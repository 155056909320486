/**
 * UI Component managing the UIGraph
 * @param label string
 * @param onEnd function
 *
 * @returns React component
 */
import React from 'react';
import { useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { animated, useSpring } from 'react-spring';
import useSleep from '../../hooks/useSleep';
import { Control, Cursor, Slide, SlideLabel, Wrapper } from './styles';
import  { IconArrow, IconCheck } from '../../utils/svg'


interface SliderElement {
  labelInitial: string;
  labelSolved: string;
  onEnd: () => void;
  isSolved?: boolean;
}

const CONFIG = {
  spring: {
    tension: 120,
    friction: 14,
  },
};

const Slider: React.FC<SliderElement> = ({ labelInitial,labelSolved, onEnd, isSolved=false}) => {
  const [isActive, setActive] = useState(!isSolved);
  const [showLabel, setShowLabel] = useState(isSolved ? labelSolved : labelInitial)
  const [dragSymbol, setDragSymbol] = useState(isSolved ? 'check' : 'arrow');
  const [isCompleted, setCompleted] = useState(isSolved);
  const wrapperRef = useRef(null);
  const slideRef = useRef(null);
  const MAX_DRAG = 158;
  const sleep = useSleep();

  const onSlideEnd = () => {
    setCompleted(true);
    setActive(false);
    setDragSymbol('check');
    setShowLabel(labelSolved);
    sleep(.8).then(() => onEnd());
  };

  const ENDPOINT = 170;
  const STARTING_POSITION = {
    x: isSolved ? ENDPOINT : 0,
    progress: 0,
  }

  const [style, api] = useSpring(STARTING_POSITION, []);

  const gestureBind = useGesture({
      onDragStart: () => isActive,
      onDragEnd: (state) => {
        const { movement: [mx] } = state;

        // If is not completed returns to the initial position
        if (mx <= MAX_DRAG) {
          api.start(STARTING_POSITION);
        }
      },
      onDrag: (state) => {
        const {
          down,
          active,
          movement: [mx],
        } = state;

        if (isCompleted) {
          return;
        }
        const relevantAx = mx;
        const progress = Math.max(relevantAx, 0);

        if (relevantAx >= MAX_DRAG && active) {
          //when the slider takes the ENDPOINT triggers the logic onSLideEnd()
          onSlideEnd();
        }

        api.start({
          x: active ? mx : ENDPOINT,
          progress,
          immediate: down,
          config: CONFIG.spring,
        });
      },
    },
    {
      enabled: isActive,
      drag: {
        bounds: slideRef,
        axis: 'x',
      },
    },
  );

  // @ts-ignore
  const bindObject = gestureBind();

  return (
    <Control>
      <Wrapper ref={wrapperRef}>
        <Slide ref={slideRef} isEnabled={isActive}>
          <animated.div
            {...bindObject}
            style={{
              x: style.x,
              pointerEvents: 'auto',
              touchAction: 'none',
              zIndex: 2,
            }}
          >
            <Cursor isEnabled={isActive} >
              {dragSymbol === 'check' ? <IconCheck /> : <IconArrow />}
            </Cursor>
          </animated.div>
            <SlideLabel isEnabled={isActive}>{showLabel}</SlideLabel>
        </Slide>
      </Wrapper>
    </Control>
  );
};

export default Slider;
