import { DescriptionText, ElementsContainer, HeadLineText,CloseBtnContainer } from './styles';
import PopUpContainer from '../PopUpContainer';
import { STATUS } from '../../services/graphService';
import { SphereType, useGraphStore } from '../../services/graphService';
import { ContentModel } from '../../utils/data';
import Slider from '../Slider';
import { HealthyButton } from '../buttons/HealthyButton';
import  { IconClose }  from '../../utils/svg'

type PopUpGraphContainerType = {
  selectedSphere: SphereType;
  onEnd: () => void;
};

const PopUpGraphContainer: React.FC<PopUpGraphContainerType> = ({
  selectedSphere,
  onEnd,
}) => {
  const contentSphereSelected = ContentModel.getContentSphereById(selectedSphere?.contentid);
  const { isWounded, isSolved } = useGraphStore();
  const isWoundedSphere = isWounded(selectedSphere);
  const isSolvedSphere = isSolved(selectedSphere);


  const renderSlider = () => (
    <Slider
      onEnd={onEnd}
      labelInitial={contentSphereSelected?.unsolved.cta_txt}
      labelSolved={contentSphereSelected?.solved.cta_txt}
      isSolved={selectedSphere?.status === STATUS.woundedSolved}
    />
  );

  const renderButton = () => (
    <HealthyButton
      isMagnetic={false}
      width="150"
      isWhite
      text={selectedSphere?.status === STATUS.healthySolved ? contentSphereSelected?.solved.cta_txt : contentSphereSelected?.unsolved.cta_txt}
      isSolved={selectedSphere?.status === STATUS.healthySolved}
      onClick={() => onEnd()}
    />
  );

  const renderActionElement = () =>
    isWoundedSphere ? renderSlider() : renderButton();

  return (
    <PopUpContainer isDelayed={true}>
    {isSolvedSphere && <CloseBtnContainer>
      <IconClose />
    </CloseBtnContainer>}
      <ElementsContainer>
        <HeadLineText>
          {(selectedSphere?.status === STATUS.wounded || selectedSphere?.status === STATUS.healthy)
            ? contentSphereSelected?.unsolved.headline
            : contentSphereSelected?.solved.headline}
        </HeadLineText>
        <DescriptionText>
          {(selectedSphere?.status === STATUS.wounded || selectedSphere?.status === STATUS.healthy)
            ? contentSphereSelected?.unsolved.text
            : contentSphereSelected?.solved.text}
        </DescriptionText>
        {renderActionElement()}
      </ElementsContainer>
    </PopUpContainer>
  );
};

export default PopUpGraphContainer;
