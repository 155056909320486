import styled from 'styled-components'
import { vp } from '../../utils/breakpoints'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LottieContainer = styled.div`
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${vp.medium(`
      width: 35%;
    `)}

`

export const Headline = styled.h3`
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
`

