import styled, {  keyframes } from 'styled-components'
import { vp } from '../../utils/breakpoints';

const initialAnimationDelay = 1000

const smallSizeBar = 242;
const gapSizeBar = 20;
const variationSizeBar = 158;

export const TextWrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  align-items: center;
  animation-delay: 1s; 
  animation-duration: 1s;
  position: absolute;
  top: -10px;
`

export const CircularBarText = styled.div`
  font-family: '72 Light';
  font-size: 12px;
  line-height: 13.8px;
  letter-spacing: normal;
  width: 40%;
  margin-bottom: 1rem;
`

export const CircularBarTime = styled.div`
  width: 65%;
  font-family: '72 Black';
  font-size: 42px;
  line-height: 42px;
  letter-spacing: normal;
`

const outerCircleKeyframes = keyframes`
   100% {stroke-dashoffset: 0;} 
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  svg {
    transform: rotate(-90deg);

    circle {
      transition: stroke-dashoffset 2s ease-in-out;
    }

    .innerCircle {
      animation: ${outerCircleKeyframes} 2s ease-in-out ${initialAnimationDelay - 700}ms forwards;
    }
    
    .outerCircle {
      animation: ${outerCircleKeyframes} 2s ease-in-out ${initialAnimationDelay - 900}ms forwards;
    }
  }
`

export const BarContainer = styled.div`
  width: ${smallSizeBar + 20}px;
  height: ${smallSizeBar + 20}px;
  position: relative;

  ${vp.medium(`
    width: ${smallSizeBar + variationSizeBar + gapSizeBar}px;
    height: ${smallSizeBar + variationSizeBar + gapSizeBar}px;
  `)}
`

export const BarBackground = styled.div`
  background: rgba(2, 28, 108, 0.69);
  border-radius: 50%;
  position: absolute;
  width: ${smallSizeBar}px;
  height: ${smallSizeBar}px;
  top: 50%;
  left: 50%;
  transform: translate(-${smallSizeBar / 2}px, -${smallSizeBar / 2}px);
  z-index: -1;

  ${vp.medium(`
    width: ${smallSizeBar + variationSizeBar}px;
    height: ${smallSizeBar + variationSizeBar}px;
    transform: translate(-${(smallSizeBar+variationSizeBar) / 2}px, -${(smallSizeBar+variationSizeBar) / 2}px);
  `)}
`