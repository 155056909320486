import {
  ProgressBarContainer,
  TimeIndicatorContainer,
  TimeContainer,
  HoursCount,
  HeadLine,
  DaysCount,
  ClockIconContainer,
  ProgressBarBack,
  ProgressBar,
} from './styles';

import { ClockIcon } from '../../utils/svg';

const TimeIndicator = ({ days, hours, percentage }) => {
  return (
    <TimeIndicatorContainer>
      <HeadLine>Current cycle time</HeadLine>
      <TimeContainer>
        <ClockIconContainer>
          <ClockIcon />
        </ClockIconContainer>
        <DaysCount>{days + 'd'}</DaysCount>
        <HoursCount>{hours + 'h'}</HoursCount>
      </TimeContainer>
      <ProgressBarContainer>
        <ProgressBarBack>
          <ProgressBar width={percentage} />
        </ProgressBarBack>
      </ProgressBarContainer>
    </TimeIndicatorContainer>
  );
};

export default TimeIndicator;
