import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex; 
  align-items: center;
  min-width: 100%;
  max-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`

export const Slide = styled.div`
  background-color: ${props => props.isEnabled ? props.theme.colors.blue : props.theme.colors.white };
  transition: background-color 0.4s ease-in;
  border-radius: 20px;
  z-index: 0;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
`

export const Cursor = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 42px;
  height: 42px;
  background-color: ${props => props.isEnabled ? props.theme.colors.white : props.theme.colors.lightBlue };
  transition: background-color 0.4s ease-in;

  border-radius: 50%;

  > svg path {
            fill: ${props => props.isEnabled ? props.theme.colors.black : props.theme.colors.white };
            transition: fill 0.4s ease-in;

        }
`

export const SlideLabel = styled.div`
  text-align: center;
  font-size: 13px;
  color: ${props => props.isEnabled ? props.theme.colors.white : props.theme.colors.blue };
  position: absolute;
  left: 50px;
  margin: 0 auto;
  pointer-events: none;
  z-index: 1;
  transition: color 0.4s ease-in;
`

export const Control = styled.div`
  display: flex;
  justify-content: space-between;
`