import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ARExperience from './views/ARExperience';
import IntroPage from './views/Intro';
import EndPage from './views/End';
import PermissionPage from './views/Permission';
import GlobalStyle, { theme } from './globalStyles';
import './App.css'

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/takeaway">
            <EndPage />
          </Route>
          <Route path="/permission">
            <PermissionPage />
          </Route>
          <Route path="/:game">
            <ARExperience />
          </Route>
          <Route path="/:game">
            <ARExperience />
          </Route>
          <Route path="/">
            <IntroPage />
          </Route>
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
