const dataJSON = require('../../data/content.json');

type WoundedElementPoint = {
  headline: string,
  text: string,
  cta_txt: string
}

export type WoundedPoint = {
  unsolved: WoundedElementPoint,
  solved: WoundedElementPoint,
  id: string,
  time_diff_hrs: number,
  label: string
}

class ContentData {
  graphGame: any
  permissionsPage: any
  landingPage: any
  finalPopup: any
  woundedPoints: WoundedPoint[]
  private static _instance:ContentData = new ContentData();

  constructor() {
    if(ContentData._instance){
      throw new Error("Error: Instantiation failed: Use ContentData.getInstance() instead of new.");
    }

    this.graphGame = dataJSON.graph_game
    this.permissionsPage = dataJSON.permission_page
    this.landingPage = dataJSON.landing_page
    this.finalPopup = dataJSON.final_popup
    this.woundedPoints = this.getWoundedPoints()

    ContentData._instance = this;
  }


  /**
   *
   * Returns the instance on a GraphData object
   * @returns GraphData
   */
  public static getInstance():ContentData{
    return ContentData._instance;
  }

  /**
   *
   * @returns WoundedPoint[]
   */
  private getWoundedPoints (): WoundedPoint[] {
    return Object.keys(this.graphGame.graph_points).map((key) => (
      {
        ...this.graphGame.graph_points[key],
        id: key
      })
    )
  }

  /**
   *
   * Get the copies for an specific Sphere
   * @param id number
   * @returns WoundedPoint
   */
  getContentSphereById (id: number): WoundedPoint {
    return this.woundedPoints.find(woundedPoint => woundedPoint.id.includes(""+id))
  }

}

export const ContentModel = ContentData.getInstance()
