import styled from 'styled-components'
import { vp } from '../../utils/breakpoints';

export const ViewContainer = styled.div`
	width: 100vw;
	height: 100vh;;
	background-color: rgba(0,0,0,0.6);
	display: flex;
`

export const TitleTxtContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30vh 2rem 0;
  text-align: left;
	width: 70%;
  opacity: 0;

  ${vp.medium(`
    margin: 30vh 3rem 0;
  `)};
`;

export const TitleTxt = styled.h2`
  font-weight: 900;
  font-size: 34px;
  line-height: 38px;
  margin: 0;

  ${vp.medium(`
    font-size: 47px;
  `)};
`;

export const ParagraphTxt = styled.h4`
	font-weight: 400;
  font-size: 15px;
  line-height: 28px;

  ${vp.medium(`
    font-size: 22px;
  `)};
`

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 60px;
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`