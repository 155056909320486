import create from 'zustand';

type ScreenGraphGameType = 'pre' | 'transition' | 'search';

type ScreensGraphType = {
  readonly pre: ScreenGraphGameType ,
  readonly transition: ScreenGraphGameType ,
  readonly search: ScreenGraphGameType
}

export const GRAPH_SCREENS: ScreensGraphType = {
  pre: 'pre',
  transition: 'transition',
  search: 'search'
}

type UIState = {
  introAnimation: number;
  popupAnimationDelay: number;
  // Graph props
  showFocusArea: boolean;
  showLegendGraph: boolean;
  showTimeIndicator: boolean;
  showInitialPopUp: boolean;
  showFinalPopUp: boolean;
  days: number;
  percentage: number;
  showImprovementCircularBar: boolean;
  screenGraphGame: ScreenGraphGameType;
  showCounter: boolean,
  showExitLink: boolean,
  // Graph props
  setShowFocusArea: (showFocusArea:boolean) => void;
  setShowLegendGraph: (showLegendGraph: boolean) => void;
  setShowTimeIndicator: (showTimeIndicator: boolean) => void;
  setShowInitialPopUp: (showInitialPopUp: boolean) => void;
  setShowFinalPopUp: (showFinalPopUp: boolean) => void;
  setTimeDays: (timeDays: number) => void;
  setShowImprovementCircularBar: (showImprovementCircularBarValue: boolean) => void;
  setScreenGraphGame: (newScreenGraphGame:ScreenGraphGameType) => void;
  setShowCounter: (showCounterStatus: boolean) => void;
  setShowExitLink: (showCounterStatus: boolean) => void;
};

export const BASE_TIME = 168; // 24 * 7

export const useUIStore = create<UIState>((set, get) => ({
  introAnimation: 2000,
  popupAnimationDelay: 1500,
  showLegendGraph: false,
  showInitialPopUp: true,
  showFinalPopUp: false,
  showTimeIndicator: false,
  days: 7,
  percentage: 0,
  showImprovementCircularBar: false,
  screenGraphGame: 'pre',
  showCounter: false,
  showFocusArea: true,
  showExitLink: false,

  setShowFocusArea: (showFocusArea:boolean) =>
    set({ showFocusArea: showFocusArea }),
  setShowLegendGraph: (showLegendGraph) =>
    set({ showLegendGraph: showLegendGraph }),
  setShowInitialPopUp: (showInitialPopUp) =>
    set({ showInitialPopUp: showInitialPopUp }),
  setShowFinalPopUp: (showFinalPopUp) =>
    set({ showFinalPopUp: showFinalPopUp }),
  setShowTimeIndicator: (showTimeIndicator) =>
    set({ showTimeIndicator: showTimeIndicator }),
  setTimeDays: (timeDays: number): void => {
    set({
      days: timeDays,
      percentage: Math.floor((timeDays * 100)/ BASE_TIME)
    });
  },
  setShowImprovementCircularBar: (showImprovementCircularBarValue) =>
    set({ showImprovementCircularBar: showImprovementCircularBarValue }),
  setScreenGraphGame: (newScreenGraphGame) =>
    set({ screenGraphGame: newScreenGraphGame }),
  setShowCounter: (showCounterStatus) =>
    set({ showCounter: showCounterStatus }),
  setShowExitLink: (showExitLinkStatus) =>
	set({ showExitLink: showExitLinkStatus }),

}));
