import { UIAnamorphicContainer, LinkExitExperience, ButtonContainer } from './styles';
import { useXrStore } from '../../services/xrService';
import ButtonArrow from '../buttons/ButtonArrow';
import { useHistory } from 'react-router-dom';
import ZoomButton from '../buttons/ZoomButton';
import InitialPopUp from '../InitialPopUp';
import { useGameStore } from '../../services/gameService';
import { useUIStore } from '../../services/uiService';


const UIAnamorphicModel: React.FC = () => {
    const history = useHistory();
    const { setScreenTouchedFirstTimeAR, screenTouchedFirstTimeAR, setScaleAnamorphicParam } = useXrStore();
    const { currentGame, isAnamorphicSolved } = useGameStore()
    const { showInitialPopUp } = useUIStore()

    return (
    <UIAnamorphicContainer>
    {!screenTouchedFirstTimeAR &&
        <>
            <ButtonContainer>
                <ButtonArrow
                    isMagnetic={false}
                    width="180"
                    isWhite
                    text="Tap to place"
                    onClick={() => setScreenTouchedFirstTimeAR()}
                    />
            </ButtonContainer>
            <ZoomButton zoomIn={() => setScaleAnamorphicParam(true)} zoomOut={() => setScaleAnamorphicParam(false)}/>
        </>
    }
    { screenTouchedFirstTimeAR && (
        <>
            { isAnamorphicSolved &&
            <ButtonContainer>
                <ButtonArrow
                isMagnetic={false}
                width="280"
                isWhite
                text="Next AR Piece"
                onClick={() => history.push('/graph_ar')}
                /> 
            </ButtonContainer>}
            { showInitialPopUp && !isAnamorphicSolved && <InitialPopUp game={currentGame} />}
            { !showInitialPopUp &&
            <LinkExitExperience to="/takeaway">
                Exit the experience
            </LinkExitExperience>}
        </>
        )
    }
    </UIAnamorphicContainer>
    );
};

export default UIAnamorphicModel;
